.inputStyle {
  width: fit-content;
}

.outline {
  outline: none !important;
}
.table th,
.table td {
  vertical-align: middle !important;
  padding: .5rem !important;
}
.pointer {
  cursor: pointer;
}
.heading {
  position: fixed;
  transform: translate(50%);
  right: 50%;
  margin-top: 25px;
}
.notification-modal {
  max-height: 500px;
  max-width: 400px;
  overflow-y: scroll;
  background: #fff;
}
.gap{
  background: #fff;
}
.gap-row{
  margin-top:20px;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.tools {
  position: relative;
  display: inline-block;
  bottom: 7px;
  right:3px  
/* border-bottom: 1px dotted black; */
}

.tools .tooltiptext1 {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tools:hover .tooltiptext1 {
  visibility: visible;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.video-call {
  height: 500px;
  width: 700px;
  background-color: white;
  position: fixed;
  z-index: 998;
  right: 0px;
}

.speciality-item {
  display: flex;
  justify-content: space-between;
  width: 95%;
  /* height: 50px; */
  margin-bottom: 8px;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #231e1e24;
}

.speciality-item:focus, .speciality-item:hover, .speciality-active {
  background-color: #31313a69;
}



.card-body {
  padding-bottom: 0 !important;
}

.card {
}

.card-doctor{
  border-radius: 10px;
  background-color: #e4e5e6;
  padding: 10px;
  width: 180px;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}